
const state = {
    isShowWx: false
}

const mutations = {
    IS_SHOW_WX(state, data) {
        state.isShowWx = data
    }
}

const actions = {
    setIsWx({ commit, state }, data) {
        commit('IS_SHOW_WX', data)
    }
}


export default {
    state,
    mutations,
    actions
}
import router from './router'
import storage from 'store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })
import { Notify } from "vant"

// , '/zdb', '/cwqs', '/phdata', '/tzgl', '/cwJt', '/lc', '/nofinancial'
const whiteList = ['/permissionNo', '/']
router.beforeEach((to, form, next) => {
    if (to.path !== '/permissionNo' && to.path !== '/') {
        // 安卓/ios请求方式不同
        storage.set('path', to.path)
        if (to.query.agentId && to.query.agentId != '' && to.query.agentId != undefined && to.query.agentId != null) {
            storage.set('agent_id', to.query.agentId) // 应用 agentid
        }
        if (to.query.secret && to.query.secret != '' && to.query.secret != undefined && to.query.secret != null) {
            storage.set('secret', to.query.secret) // 应用 secret
        }
    }
    NProgress.start()
    if (storage.get('ADMIN_TOKEN')) {
        if (to.path === '/') {
            next(storage.get('path'))
            NProgress.done()
        } else {
            next()
            NProgress.done()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
            NProgress.done()
        } else {
            next({
                path: '/',
                query: to.query
            })
            NProgress.done()
        }
    }
})

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/scss/index.scss"
import "@/assets/scss/nprogress.scss"
import "./permission.js"
import Card from "@/components/Card/index.vue"
import TabBox from "@/components/TabBox/index.vue"
import Vant from 'vant';
import 'vant/lib/index.css';
import scrollTable from "@/components/ScrollTable/index.vue"
import DatePicker from "@/components/DatePicker/index.vue"
import Num from "@/components/Num/index.vue"

Vue.use(Vant);
Vue.component('Card', Card)
Vue.component('TabBox', TabBox)
Vue.component('scrollTable', scrollTable)
Vue.component('DatePicker', DatePicker)
Vue.component('Num', Num)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

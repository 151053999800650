<template>
    <div id="app">
        <div :class="classNmae">
            <div :class="classNmaeBody">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
import storage from 'store'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            classNmae: "", // body class
            classNmaeBody: "", // model-body
            id:'',
            ids:'',
            time: null
            // admin_token_
        }
    },
    computed: {
        ...mapGetters(['isShowWx']),
    },
    created() {
        // 检测当前打开机型
        this.getModelView()
        // 监听窗口变化
        window.addEventListener('resize', this.getModelView)
        // this.getCode()
    },
    beforeDestroy() {
        // 离开页面删除监听
        window.removeEventListener('resize', this.getModelView)
    },
    mounted() {
        this.$store.dispatch('setIsWx', true)
    },
    methods: {
        getCode() {
            let user = navigator.userAgent
            var isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1
            console.log(this.isShowWx)
            if (!this.isShowWx) {
                let agentId = storage.get('agent_id')
                let secret = storage.get('secret')
                // 安卓/ios请求方式不通
                if (isAndroid) {
                    // 安卓
                    window.open("https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww0ac87dbe717cfb69&redirect_uri="+encodeURI('https://yjzdsp.sdsdbjt.com/')+"&response_type=code&scope=snsapi_privateinfo&agentid="+agentId+"#wechat_redirect")
                } else {
                    // ios
                    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww0ac87dbe717cfb69&redirect_uri="+encodeURI('https://yjzdsp.sdsdbjt.com/')+"&response_type=code&scope=snsapi_privateinfo&agentid="+agentId+"#wechat_redirect"
                }
            }
        },
        // 检测当前打开机型
        getModelView() {
            let ua = navigator.userAgent
            let isAndroid = /(?:Android)/.test(ua) // 安卓
            let isIpd = (navigator.userAgent.match(/(iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) // pad
            let isIphone = /(iPhone)/.test(ua) // 苹果
            if (isAndroid || isIphone) {
                this.classNmae = 'is-phone'
                this.classNmaeBody = 'model-phone-body'
            } else if (isIpd) {
                this.classNmae = 'is-pad'
                this.classNmaeBody = 'model-pad-body'
            } else {
                this.classNmae = 'is-pc'
                this.classNmaeBody = 'model-pc-body'
            }
        },
    },
}
</script>
<style lang="scss">
.is-phone {
    width: 100vw;
    .model-phone-body {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom right, #D7B28E 0%,#A97D59) no-repeat;
        font-size: 20px;
        // border-bottom-left-radius: 24px;
        // border-bottom-right-radius: 24px;
    }
}
.is-pad {
    width: 100%;
    .model-pad-body {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom right, #D7B28E 0%,#A97D59) no-repeat;
        font-size: 24px;
        // border-bottom-left-radius: 24px;
        // border-bottom-right-radius: 24px;
    }
}
.is-pc {
    width: 100%;
    .model-pc-body {
        margin: 0 auto;
        width: 1000px;
        height: 100%;
        background: linear-gradient(to bottom right, #D7B28E 0%,#A97D59) no-repeat;
        font-size: 26px;
        // border-bottom-left-radius: 24px;
        // border-bottom-right-radius: 24px;
    }
}
</style>

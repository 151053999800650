<!--
* @Description: card
* @Author: sunchunchun
* @CreateDate: 2024/04/17 15:40:00
* @FileName: index.vue
!-->
<template>
    <div class="card-box" :style="style">
        <div v-if="title!='' || translate != ''" class="card-title">
            <div class="title" v-if="title!=''">{{ title }}</div>
            <div class="translate" v-if="translate!=''">{{ translate }}</div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Card",
    props: {
        title: {
            type: String,
            default: "",
        },
        translate: {
            type: String,
            default: "",
        },
        classStyle: {
            type: Object,
            default: () => {},
        },
        className: {
            type: Object,
            default: () => {},
        }
    },
    data () {
        return {
            style: {},
            class: {},
        }
    },
    watch: {
        classStyle: {
            handler(newV) {
                this.style = newV
            },
            deep: true
        },
        className: {
            handler(newV) {
                this.class = newV
            },
            deep: true
        },
    },
    created() {
        this.style = this.classStyle
        this.class = this.className
    },
    methods: {

    }
}
</script>

<style lang='scss' scoped>
.card-box{
    background: #fff;
    border-radius: 24px;
    padding: 20px 25px;
    .card-title{
        overflow: hidden;
    }
    .title{
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        float: left;
    }
    .translate{
        display: inline-block;
        float: right;
        color: #626262;
        font-size: 14px;
    }
}
</style>
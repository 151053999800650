import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters"
import login from "./model/login"
Vue.use(Vuex)


const state = {}

const mutations = {}

const actions = {}

const modules = {
    login
}

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules,
})

export default store

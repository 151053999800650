import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import("@/views/login/index.vue")
    }, {
        path: '/zdb',
        name: 'zdb',
        component: () => import("@/views/zdb/index.vue")
    }, {
        path: '/tzgl',
        name: 'tzgl',
        component: () => import("@/views/tzgl/index.vue")
    }, {
        path: '/cwJt',
        name: 'cwJt',
        component: () => import("@/views/cwJt/index.vue")
    }, {
        path: '/lc',
        name: 'lc',
        component: () => import("@/views/lc/index.vue")
    }, {
        path: '/cwqs',
        name: 'cwqs',
        component: () => import("@/views/cwqs/index.vue")
    }, {
        path: '/nofinancial',
        name: 'nofinancial',
        component: () => import("@/views/nofinancial/index.vue")
    }, {
        path: '/phdata',
        name: 'phdata',
        component: () => import("@/views/ph/index.vue")
    }, {
        path: "/permissionNo",
        name: 'permissionNo',
        component: () => import("@/views/permissionNo/index.vue")
    }, {
        path: "*",
        component: () => import("@/views/error/404.vue")
    },{
        path:"/backlog/index",
        name: '/backlog/index',
        component:()=>import("@/views/flow/backLog/index.vue")
    },{
        path:"/ecbm/audit",
        name: '/ecbm/auditl',
        component:()=>import("@/views/flow/backLog/detail.vue")
    },{
        path:"/copyTo/index",
        name: '/copyTo/index',
        component:()=>import("@/views/flow/copyTo/index.vue")
    },{
        path:"/ecbm/detail",
        name: '/ecbm/detail',
        component:()=>import("@/views/flow/copyTo/detail.vue")
    },{
        path:"/created/index",
        name: '/created/index',
        component:()=>import("@/views/flow/created/findex.vue")
    },{
        path:"/ecbm/add",
        name: '/ecbm/add',
        component:()=>import("@/views/flow/created/detail.vue")
    },{
        path:"/executed/index",
        name: '/executed/index',
        component:()=>import("@/views/flow/executed/index.vue")
    },{
        path:"/submit/index",
        name: '/submit/index',
        component:()=>import("@/views/flow/submit/index.vue")
    },{
        path:"/flow/index",
        name:"/flow/index",
        component:()=>import("@/views/flow/index.vue")
    }
]



//   base: process.env.BASE_URL,
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router

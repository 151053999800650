<!--
* @Description: 滚动表格
* @Author: sunchunchun
* @CreateDate: 2024/04/18 11:39:29
* @FileName: index.vue
!-->
<template>
    <div>
        <table class="item-title">
            <thead>
                <tr>
                    <th v-for="(item, index) in columns" :key="index">
                        <span @click.stop="onSort(item)">{{ item.title }}</span>
                    </th>
                </tr>
            </thead>
        </table>
        <!-- <vue-seamless-scroll :data="list" class="warp" :class-option="options"> -->
            <div class="scroll">
                <table class="item-body">
                        <tr v-for="(item, index) in tableList" :key="index" width="100%" class="tr-bac-red tr-bac-yel">
                            <td v-for="(row, inx) in columns" :style="inx===0?{fontWeight: 550, color: '#000'}:''">
                                {{ item[row.key] }}
                            </td>
                        </tr>
                </table>
            </div>
        <!-- </vue-seamless-scroll> -->
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll"
export default {
    name: "scrollTable",
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        vueSeamlessScroll
    },
    data () {
        return {
            options: {
                step: 0.3, // 滚动速度
                hoverStop: false,
                direction: 1, // 0 向下  1 向上  2 向左  3 向右
            },
            tableList: [],
            sortLable: '',
            sortDri: 'asc',
        }
    },
    created() {
        this.tableList = JSON.parse(JSON.stringify(this.list))
    },
    methods: {
        /**
         * 排序
         * @param {*} row 
         */
        onSort(row) {
            let key = row.key
            // 
            if (this.sortLable === key) {
                this.sortDri = this.sortDri === 'asc' ? 'desc' : 'asc'
            } else {
                this.sortLable = key
                this.sortDri = 'asc'
            }
            // sort排序
            this.tableList.sort((a, b) => {
                let modifier = 1
                if (this.sortDri) modifier = -1
                if (a[this.sortLable] < b[this.sortLable]) return -1 * modifier
                if (a[this.sortLable] > b[this.sortLable]) return 1 * modifier
                return 0
            })
            // 重置当前排序
            if (this.sortDri === 'desc') {
                this.sortLable = ''
                this.sortDri = 'asc'
                this.tableList = [...this.list]
            }
        },
        /**
         * 获取日期差
         * @param {*} startDate 
         */
        getDateDay(startDate) {
            let start = new Date(startDate)
            let endDate = new Date()
            // 向上取整
            let date = Math.ceil((endDate - start) / (1000*60*60*24))
            return date
        },
    }
}
</script>

<style lang='scss' scoped>
.warp{
    height: 250px;
    // width: 100%;
    margin: 0 auto;
    overflow: hidden;
    // padding: 0 10px;
}
.scroll{
    width: 100%;
    overflow: hidden;
    height: 285px;
    overflow-y: scroll;
}
.item-body{
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;
    max-height: 100%;
    tr{
        width: 100%;
    }
    tr:nth-child(even){
        background-color: #FAF7F5;
    }
    tr:nth-child(odd){
        background-color: #fff;
    }
    // .tr-bac-red{
    //     background: linear-gradient(to right, rgb(255, 222, 222), rgb(255, 146, 146));
    // }
    // .tr-bac-yel{
    //     background: linear-gradient(to right, rgb(255, 255, 209), rgb(255, 241, 132));
    // }
    td{
        font-weight: 500;
        width: 20%;
        text-align: center;
        font-size: 14px;
        padding: 12px 5px;
        border-bottom: 1px solid #F9F6F4;
        color: #626262;
    }
}
.item-title{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    // background: linear-gradient(to right, yellow, red);
    background: linear-gradient(to right, #EEE8E3, #F8F5F3);
    tr{
        width: 100%;
        color: #626262;
    }
    th{
        font-weight: 500;
        text-align: center;
        font-size: 14px;
        padding: 10px 0;
    }
}
</style>
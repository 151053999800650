<!--
* @Description: 数字动画
* @Author: sunchunchun
* @CreateDate: 2024/04/23 17:43:00
* @FileName: index.vue
!-->
<template>
    <span>
        <countTo :duration="1000" :startVal="0" :endVal="num" :decimals="decimals" separator=",">
            <slot></slot>
        </countTo>
    </span>
</template>

<script>
import countTo from "vue-count-to"
export default {
    name: "Num",
    components: { countTo },
    props: {
        type: {
            type: String,
            default: "Number"
        }
    },
    data () {
        return {
            num: 0,
            decimals: 2
        }
    },
    created() {
        if (this.$slots.default[0].text) {
            this.num = Number(this.$slots.default[0].text)
        }
        if (this.type === '%') {
            this.decimals = 0
        } else {
            let num = this.$slots.default[0].text
            if (num && num.indexOf('.') != -1) {
                let length = num.length
                let index = num.indexOf('.')
                this.decimals = length - (index + 1)
            } else {
                this.decimals = 0
            }
        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style lang='scss' scoped>

</style>
<!--
* @Description: tab分类
* @Author: sunchunchun
* @CreateDate: 2024/04/11 17:16:23
* @LastEditTime: 2024/04/11 17:16:23
* @FileName: index.vue
!-->
<template>
    <div class="tab-box">
        <ul class="tab-ul">
            <li class="tab-li" v-for="(item, index) in val" :key="index" @click="onChangeTab(index)">
                <div :class="num === index?'tab-font-active':'tab-font'">{{ item }}</div>
                <div :class="num === index?'border-active':'border'"></div>
            </li>
        </ul>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TabBox",
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            num: 0,
            val: []
        }
    },
    components: {},
    watch:{
        list:{
            handler(newview){
                this.val=newview
            },
            deep:true
        }
    },
    created() {
        this.val=this.list
    },
    methods: {
        /**
         * 切换tab
         * @param {Number} index 
         */
        onChangeTab(index) {
            this.num = index
            this.$emit('mode', index)
        },
    }
}
</script>

<style lang='scss' scoped>
.tab-box{
    background: #f9f6f4;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 20px 13px;
    min-height: 100vh;
    overflow: hidden;
    box-shadow: 1px 1px 58px -25px #000;
    .tab-ul{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-around;
        .tab-li{
            margin: 0;
            padding: 0;
            font-size:16px;
            .border{
                width: 0;
                margin: 0 auto;
                border-bottom: 4px solid #f9f6f4;
                margin-bottom: 15px;
            }
            .border-active{
                width: 40px;
                margin: 0 auto;
                border-bottom: 4px solid #7f502f;
                transition: all 0.3s ease-in;
                border-radius: 15px;
                margin-bottom: 15px;
            }
        }
    }
    .tab-font{
        color: #000;
        margin-bottom: 12px;
    }
    .tab-font-active{
        color: #7f502f;
        margin-bottom: 12px;
    }
}
</style>
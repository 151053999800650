<!--
* @Description: 时间选择器
* @Author: sunchunchun
* @CreateDate: 2024/04/23 11:27:34
* @FileName: index.vue
!-->
<template>
    <div>
        <van-popup v-model="show" position="bottom" @click-overlay="overlay">
            <van-datetime-picker
                v-model="dataCreate"
                :type="isDay?'date':'year-month'"
                title="选择日期"
                @confirm="onConfirm"
                @cancel="cancel"
                :formatter="formatter"
                :minDate="minDate"
                :maxDate="maxDate"
                color="#A97D59">
            </van-datetime-picker>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: "DatePicker",
    props: {
        isshow: {
            type: Boolean,
            default: false
        },
        isDay: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            show: false, // 日期展示隐藏
            dataCreate: new Date(), // 默认日期
            maxDate: new Date(), // 最大日期
            minDate: new Date('2019-01'), // 最小日期
            getDay: null, // 选中的日期
            dayShow: false,
        }
    },
    watch: {
        isshow(newV) {
            this.show = newV
        }
    },
    created() {
        this.show = this.isshow
        this.dayShow = this.isDay
    },
    methods: {
        /**
         * 选择当前日期
         * @param {Date} day 
         */
        onConfirm(day) {
            let year = day.getFullYear()
            let month = day.getMonth() + 1
            if (month < 10) { month = '0' + month }
            let days = undefined
            // 开启日选择后
            if (this.dayShow) {
                days = day.getDate()
            } else {
                let date = new Date()
                let newMonth = date.getMonth() + 1
                let newDay = undefined
                // 如果在本月 就获取当前日
                if (newMonth == month) {
                    newDay = date.getDate()
                } else {
                    newDay = new Date(year, month, 0).getDate()
                }
                days = newDay
            }
            if (days < 10) { days = '0' + days }
            this.getDay = `${year}-${month}-${days}`
            this.$nextTick(() => {
                this.$emit('update:isshow', false)
                this.$emit('confirm', this.getDay)
            })
        },
        /**
         * 时间选择器取消按钮
         */
        cancel() {
            this.$nextTick(() => {
                this.$emit('update:isshow', false)
                this.$emit('confirm', this.getDay)
            })
        },
        /**
         * 遍历日历
         * @param {Object} day 
         */
        formatter(type, val) {
            if (!this.dayShow) {
                if (type === 'year') {
                    return val + '年'
                } else if (type === 'month') {
                    return val + '月'
                }
            } else {
                if (type === 'year') {
                    return val + '年'
                } else if (type === 'month') {
                    return val + '月'
                } else if (type === 'day') {
                    return val + '日'
                }
            }
        },
        overlay() {
            this.$emit('update:isshow', false)
        }

    }
}
</script>

<style lang='scss' scoped>

</style>